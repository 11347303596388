import { useWallet } from "@solana/wallet-adapter-react";
import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";

function User({ className }) {
  const { publicKey } = useWallet();
  const [currentUser] = useContext(UserContext);

  return (
    <div className={`flex items-center space-x-4 ${className}`}>
      <div className="w-10 h-10 bg-white rounded-full">
        {currentUser?.avatar && <img src={currentUser?.avatar} alt={currentUser?.name} className="w-full rounded-full" />}
      </div>
      <div className="text-white font-cursive">{publicKey?.toString()?.substr(0, 4) + "..." + publicKey?.toString()?.substr(-4)}</div>
    </div>
  );
}

export default User;
