import { Icon } from "@iconify/react";
import React, { useContext, useState } from "react";
import { UserCollectionsContext } from "../../context/UserCollectionsContext";
import { UserContext } from "../../context/UserContext";

function ProfileBox() {
  const [showMetadata, setShowMetadata] = useState(false);
  const [currentUser, setCurrentUser] = useContext(UserContext);
  const [userCollections, selectedCollection, setSelectedCollection] = useContext(UserCollectionsContext);
  
  const handleFavoriteCollection = (image) => {
    setCurrentUser(prevState => ({...prevState, avatar: image}));
  }

  return (
    <>
      <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/profile-box.webp`} alt="Profile" className="w-full" />
      {/* Sidebar */}
      <div
        className="absolute left-11 top-6 w-36 h-full bg-contain bg-no-repeat"
        style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/miawwhalla/profile-bar.webp')` }}
      >
        <div className="absolute top-10 left-7 w-[5.75rem] h-3/4 space-y-4 scrollbar-thin scrollbar-thumb-[#ADADF0] scrollbar-track-[#7575A0] scrollbar-rounded-lg">
          {userCollections.length > 0 &&
            userCollections.map((i) => (
              <img
                src={i.collection?.image}
                alt={i.collection?.name}
                key={i.id}
                className={`w-[4.6rem] h-[4.6rem] rounded-md cursor-pointer hover:border-4 border-transparent hover:border-[#ADADF0] transition-all ${
                  i.id == selectedCollection?.id && "border-4 border-[#ADADF0]"
                }`}
                onClick={() => setSelectedCollection(i)}
              />
            ))}
          {userCollections.length <= 5 && (
            <>
              <div className="w-[4.6rem] h-[4.6rem] rounded-xl bg-[#7575A0] cursor-not-allowed"></div>
              <div className="w-[4.6rem] h-[4.6rem] rounded-xl bg-[#7575A0] cursor-not-allowed"></div>
              <div className="w-[4.6rem] h-[4.6rem] rounded-xl bg-[#7575A0] cursor-not-allowed"></div>
              <div className="w-[4.6rem] h-[4.6rem] rounded-xl bg-[#7575A0] cursor-not-allowed"></div>
            </>
          )}
        </div>
      </div>
      {/* Main Area */}
      <img src={selectedCollection?.collection?.image} alt={selectedCollection?.collection?.name} className="absolute top-14 left-48 w-[65%] shadow-hard" />

      {/* Set as favorite */}
      <div className="absolute top-0 right-28">
        <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/attr.webp`} alt="Attr" className="w-20" />

        <div
          className="absolute inset-0 flex justify-center items-center pt-3 cursor-pointer hover:scale-110 transition-all active:scale-100"
          onClick={() => handleFavoriteCollection(selectedCollection?.collection?.image)}
        >
          {currentUser?.avatar == selectedCollection?.collection?.image ? (
            <Icon icon="heroicons-solid:heart" className="w-10 h-10 text-red-600" />
          ) : (
            <Icon icon="heroicons-outline:heart" className="w-10 h-10" />
          )}
        </div>
      </div>

      {/* Metadata */}
      {showMetadata && (
        <div className="absolute top-0 -right-12 w-64">
          <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/window.webp`} alt="window" className="absolute top-0 right-0 w-64 opacity-75" />
          <div className="relative z-10 px-10 py-8 space-y-1 text-[#141F58]">
            <h4 className="font-bold text-lg">{selectedCollection?.collection?.name}</h4>
            <div>
              <h6 className="font-bold">Head:</h6>
              <p className="text-sm font-medium">Banana 5.6% (FP: 70 SOL)</p>
            </div>
            <div>
              <h6 className="font-bold">Eye:</h6>
              <p className="text-sm font-medium">Banana 0.7% (FP: 100 SOL)</p>
            </div>
            <div>
              <h6 className="font-bold">Body:</h6>
              <p className="text-sm font-medium">Fishmsn Karate 0.1% (FP: 200 SOL)</p>
            </div>
            <div>
              <h6 className="font-bold">Weapon:</h6>
              <p className="text-sm font-medium">Ramen 2% (FP: 50 SOL)</p>
            </div>
            <div>
              <h6 className="font-bold">Background:</h6>
              <p className="text-sm font-medium">Ramen 10% (FP: 40 SOL)</p>
            </div>
          </div>
        </div>
      )}

      <div
        className="cursor-pointer hover:scale-105 active:scale-100 transition-all absolute -bottom-2 right-16 w-80 h-24 bg-contain bg-no-repeat text-center flex items-center justify-center font-cursive text-xl pt-2"
        style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/miawwhalla/profile-name.webp')` }}
        onClick={() => setShowMetadata(!showMetadata)}
      >
        <h6 className="text-[#141F58]">{selectedCollection?.collection?.name}</h6>
      </div>
    </>
  );
}

export default ProfileBox;
