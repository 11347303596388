import { WalletModalProvider, WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import toast from "react-hot-toast";
import LoadingSpinner from "../LoadingSpinner";

export default function ConnectWallet() {
  const [isLoading, setIsLoading] = useState(false);

  const handleConnectMetamask = async () => {
    setIsLoading(true);

    if (!window.ethereum) {
      setIsLoading(false);
      toast.error("Install metamask extension!");
      return;
    }

    let accounts;
    try {
      accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
    } catch (e) {
      console.log(e);
      toast.error("Failed to connect to wallet");
      setIsLoading(false);
      return;
    }

    localStorage.setItem("metamask_address", accounts[0] ?? null);
    setIsLoading(false);
    window.location.reload();
  };

  return (
    <div className="relative flex justify-center items-center h-full z-10">
      {isLoading && <LoadingSpinner />}

      <div className="container">
        <div className="max-w-2xl mx-auto">
          <div className="bg-center bg-cover md:bg-contain bg-no-repeat p-11 mb-12" style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/whitelist/bg-disclaimer.webp')` }}>
            <h1 className="font-cursive text-xl text-black text-center mb-3">
              Step-by-step instructions
              <br />
              for Whitelist registration
            </h1>
            <ol className="list-outside list-decimal font-semibold text-black text-sm md:text-base">
              <li>Ensure you are on khuga.io</li>
              <li>
                Connect your ETH wallet.
                <h6 className="font-bold">Connecting your wallet is for authentication purposes only. No financial transactions will occur.</h6>
              </li>
              <li>
                If your wallet address is already registered with us, log in to your Twitter account to allow our <a href="https://twitter.com/khugabot" target="_blank">@Khugabot</a> to tweet your LoA.
              </li>
              <li>
                If your wallet address is not yet registered, click on the "I have a role in Discord" button, then log in to your Twitter account to allow our
                <a href="https://twitter.com/khugabot" target="_blank">@Khugabot</a> to tweet your LoA.
              </li>
              <li>Your wallet has now been officially submitted to our database.</li>
            </ol>
          </div>
          <h2 className="mb-8 font-cursive text-2xl text-black text-center">Sign in with your wallet to continue</h2>
          {!window.ethereum && isMobile ? (
            <>
              <a
                href={`https://metamask.app.link/dapp/https://khuga.io/whitelist`}
                className="relative hover:opacity-80 hover:shadow hover:-translate-y-1 transition-all"
              >
                <img src="/images/button/connect-wallet.webp" alt="Menu" className="h-20" />
                <span className="absolute top-0 w-full text-center mt-6 text-white font-cursive text-lg">Sign-In With Metamask</span>
              </a>
              {/* <a
                href={`https://link.trustwallet.com/open_url?coin_id=56&url=https://khuga.io/whitelist`}
                className="relative hover:opacity-80 hover:shadow hover:-translate-y-1 transition-all"
              >
                <img src="/images/button/connect-wallet.webp" alt="Menu" className="h-20" />
                <span className="absolute top-0 w-full text-center mt-6 text-white font-cursive text-lg">Sign-In With TrustWallet</span>
              </a> */}
            </>
          ) : (
            <div className="text-center">
              <button onClick={handleConnectMetamask} className={`relative hover:opacity-80 hover:shadow hover:-translate-y-1 transition-all`}>
                <img src="/images/button/connect-wallet.webp" alt="Menu" className="h-20" />
                <span className="absolute inset-x-0 top-0 w-full text-center mt-6 text-white font-cursive text-lg">Connect With Your Wallet</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
