import { Link } from "react-router-dom";
import LayoutMiawhalla from "../../layouts/LayoutMiawhalla";
import ProfileBox from "../../partials/miawwhalla/ProfileBox";

function MiawwhallaDashboard() {
  
  return (
    <LayoutMiawhalla>
      <div className="grid grid-cols-2 items-center">
        <div className="relative">
          <ProfileBox />
        </div>
        <div>
          <Link to={`${process.env.PUBLIC_URL}/miawwhalla/profile`}>
            <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/btn-profile.webp`} alt="Profile" className="w-11/12 mb-4 transition-all active:scale-95" />
          </Link>
          <Link to={`${process.env.PUBLIC_URL}/miawwhalla/event`}>
            <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/btn-enter.webp`} alt="Enter" className="w-full ml-12 transition-all active:scale-95" />
          </Link>
        </div>
      </div>
    </LayoutMiawhalla>
  );
}

export default MiawwhallaDashboard;
