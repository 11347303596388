import LayoutHome from "../layouts/LayoutHome";
import { useLocation, Navigate } from "react-router-dom";
import { WalletModalProvider, WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { isMobile } from "react-device-detect";
import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect } from "react";

function ConnectWallet() {
  const location = useLocation();
  const wallets = useWallet();
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  if (isAuthenticated == "true") {
    return <Navigate to="/home" state={{ from: location }} replace />;
  }

  useEffect(() => {
    console.log(wallets);
    if (!wallets.connecting && !wallets.connected) {
      console.log("cancelled");
    } else if (!wallets.connecting && wallets.connected) {
      localStorage.setItem("isAuthenticated", true);
      window.location.reload();
    }
  }, [wallets]);

  return (
    <LayoutHome>
      <div className="w-full h-screen bg-[#C2C2C2]">
        <div className="absolute z-0 inset-0 bg-cover bg-bottom bg-no-repeat bg-[url('../../public/images/bg/miawhalla-mobile.webp')] sm:bg-[url('../../public/images/bg/miawhalla.webp')]"></div>

        <div className="relative flex justify-center items-center h-full z-10">
          <div className="-translate-y-52 sm:translate-y-12">
            {isMobile && window.solana === undefined ? (
              <a
                href={`https://phantom.app/ul/browse/${encodeURIComponent("https://khuga.io/connect")}?ref=${encodeURIComponent(
                  "https://khuga.io"
                )}`}
                className="relative hover:opacity-80 hover:shadow hover:-translate-y-1 transition-all"
              >
                <img src="/images/button/connect-wallet.webp" alt="Menu" className="h-20" />
                <span className="absolute top-0 w-full text-center mt-6 text-white font-cursive text-lg">Open in Phantom App</span>
              </a>
            ) : (
              <WalletModalProvider>
                <div className="flex justify-center items-center">
                  <WalletMultiButton className="relative hover:opacity-80 hover:shadow hover:-translate-y-1 transition-all !p-0">
                    <img src="/images/button/connect-wallet.webp" alt="Menu" className="h-20" />
                    <span className="absolute top-0 w-full text-center mt-2 text-white font-cursive text-lg">Connect With Your Wallet</span>
                  </WalletMultiButton>
                </div>
              </WalletModalProvider>
            )}
          </div>
        </div>
      </div>

      <footer className="absolute inset-x-0 bottom-0 z-10 bg-black text-white py-2 md:py-4">
        <div className="max-w-6xl mx-auto px-4 pb-2 sm:pb-4 relative z-10 text-center md:text-left">
          <span className="font-bold text-xs">©2023 Khuga NFT</span>
        </div>
      </footer>
    </LayoutHome>
  );
}

export default ConnectWallet;
