import { ethers } from "ethers";

const _signMessage = async (web3, wallet, pubkey, nonce) => {
  let signature;
  await web3.send("eth_requestAccounts", []);
  const signer = web3.getSigner();
  signature = await signer.signMessage(
    `Connecting your wallet is for authentication purposes only. No financial transactions will occur.\nWHITELIST:${pubkey}:${nonce + 1}`
  );

  return signature;
};

export const useWeb3Auth = () => {
  // if (!window.ethereum) throw "No Ethereum Provider Found";
  let web3;
  if (window.ethereum) {
    web3 = new ethers.providers.Web3Provider(window.ethereum);
  }

  return {
    doWeb3Auth: (pubkey, nonce) => _signMessage(web3, null, pubkey, nonce),
    //   doInitAuth: (pubkey) => initAuth(pubkey),
  };
};
