import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";

function TraitGroup({ title, items, expand, onChange }) {
  const [expanded, setExpanded] = useState(expand);
  const [traits, setTraits] = useState([]);

  const handleCheckboxChanges = (e) => {
    let newArray = [...traits, e.target.id];
    if (traits.includes(e.target.id)) {
      newArray = newArray.filter((t) => t !== e.target.id);
    }
    setTraits(newArray);
  }

  useEffect(() => {
    onChange(traits);
  }, [traits]);


  return (
    <div className="mt-4 pt-4">
      <div className="flex items-center space-x-2 mb-4 cursor-pointer" onClick={() => setExpanded((expanded) => !expanded)}>
        <div className="-ml-0">
          <img
            src={expanded ? process.env.PUBLIC_URL + "/images/gallery/minus.webp" : process.env.PUBLIC_URL + "/images/gallery/plus.webp"}
            alt="Collapse"
            className="h-10"
          />
        </div>
        <span className="font-bold text-xl">{title}</span>
      </div>
      <Transition
        show={expanded}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="space-y-2 pl-2">
          {items.map((i) => (
            <div className="flex items-center space-x-2" key={i}>
              <input type="checkbox" id={i} className="h-6 w-6 accent-pink-500" onChange={(e) => handleCheckboxChanges(e)} />
              <label htmlFor={i} className="text-lg">
                {i}
              </label>
            </div>
          ))}
        </div>
      </Transition>
    </div>
  );
}

export default TraitGroup;
