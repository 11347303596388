import { Icon } from "@iconify/react";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import ProfileBox from "../../partials/miawwhalla/ProfileBox";
import User from "../../partials/miawwhalla/User";

function MiawwhallaBattle() {
  const [status, setStatus] = useState("BATTLE SCENE");

  return (
    <>
      <Toaster />
      {/* Show on mobile */}
      <div className="fixed inset-0 bg-white z-50 flex justify-center items-center lg:hidden">
        <div className="text-center px-4">
          <Icon icon="akar-icons:circle-x" className="w-10 h-10 mx-auto text-red-400" />
          <h2 className="font-cursive text-3xl mt-4">Sorry</h2>
          <p>Miawwhalla is only accessible via desktop</p>
          <Link to={`${process.env.PUBLIC_URL}/`} className="px-4 py-2 ml-2 mr-4 mt-6 bg-primary-800 text-white rounded inline-block font-cursive text-sm">
            Back to home
          </Link>
        </div>
      </div>

      {/* Show on desktop */}
      <div
        className="min-h-screen bg-cover bg-center overflow-hidden"
        style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/miawwhalla/bg.webp')` }}
      >
        <div className="container mx-auto mb-16">
          <div className="flex justify-between items-start pt-6">
            <User className="mt-4" />
            <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/logo.webp`} alt="Logo" className="h-32" />
          </div>
        </div>

        <div className="relative container mx-auto bg-[#D2D5E2] flex justify-center items-center rounded-lg">
          {status === "BATTLE SCENE" && (
            <h1 className="font-cursive text-6xl py-48" onClick={() => setStatus("BATTLE SCENE DONE")}>
              BATTLE SCENE
            </h1>
          )}
          {status === "BATTLE SCENE DONE" && (
            <div className="py-48 text-center">
              <h1 className="font-cursive text-6xl" onClick={() => setStatus("CLAIMED!")}>
                BATTLE SCENE DONE
              </h1>
              <p className="text-2xl">Claim airdrop (lazy minting)</p>
            </div>
          )}
          {status === "CLAIMED!" && (
            <h1 className="font-cursive text-6xl py-48" onClick={() => setStatus("CLAIMED!")}>
              CLAIMED!
            </h1>
          )}

          {(status === "BATTLE SCENE" || status === "BATTLE SCENE DONE") && (
            <Link to={`${process.env.PUBLIC_URL}/miawwhalla/event/1`}>
              <button className="absolute bottom-8 left-8 bg-indigo-300 px-5 py-2">BACK</button>
            </Link>
          )}
          {status === "BATTLE SCENE DONE" && (
            <button className="absolute bottom-8 right-8 bg-indigo-300 px-5 py-2" onClick={() => setStatus("CLAIMED!")}>
              Claim
            </button>
          )}
          {status === "CLAIMED!" && (
            <Link to={`${process.env.PUBLIC_URL}/miawwhalla/profile`}>
              <button className="absolute bottom-8 left-1/2 bg-indigo-300 px-5 py-2">Inventory</button>
            </Link>
          )}
        </div>
      </div>
    </>
  );
}

export default MiawwhallaBattle;
