import toast from "react-hot-toast";
import LayoutMiawhalla from "../../layouts/LayoutMiawhalla";
import { useEffect } from "react";
import { useState } from "react";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import CardCollection from "../../partials/card/CardCollection";

function MiawwhallaCollections() {
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [collectionList, setCollectionList] = useState([]);

	useEffect(() => {
		internalAPIClient
      .get(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/collection`)
			.then((r) => {
				setCollectionList(r?.data?.data ?? []);
			})
      .catch((e) => {
        toast.error("Error: " + e);
      });
	}, []);

  return (
    <LayoutMiawhalla title="Collections">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-5">
          {collectionList.map((item, i) => (
            <div key={i}>
              <CardCollection image={item.collections?.image} title={item.collections?.name} />
            </div>
          ))}
        </div>
      </div>
    </LayoutMiawhalla>
  );
}

export default MiawwhallaCollections;
