import { PublicKey } from "@solana/web3.js";
import BN from "bn.js";
import axios from "axios";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { default as bs58 } from "bs58";

const initAuth = async (pubkey) => {
  // Axios Call to get Nonce
};

const signMessage = async (conn, wallet, pubkey, nonce) => {
  let encodedMessage = new TextEncoder().encode(`MIAWHALLA:${pubkey}:${nonce + 1}`);
  try {
    const signature = await wallet.signMessage(encodedMessage, "utf-8");
    return bs58.encode(signature);
  } catch (err) {
    throw new Error(err);
  }
};

export const useWeb3Auth = () => {
  const connection = useConnection();
  const wallet = useWallet();

  return {
    doWeb3Auth: (pubkey, nonce) => signMessage(connection, wallet, pubkey, nonce),
    doInitAuth: (pubkey) => initAuth(pubkey),
  };
};
