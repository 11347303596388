import { useState } from "react";
import { Icon } from "@iconify/react";
import LayoutHome from "../layouts/LayoutHome";
import TraitGroup from "../partials/filter/TraitGroup";
import InfiniteScroll from "react-infinite-scroller";
import CollectionLoader from "../partials/loader/CollectionLoader";
import { useEffect } from "react";

function Gallery() {
  const [showFilter, setShowFilter] = useState(false);
  const [collectionList, setCollectionList] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const [search, setSearch] = useState("");
  const [newSearch, setNewSearch] = useState(false);
  const [traits, setTraits] = useState("");

  useEffect(() => {
    loadCollectionList(1);
  }, [search, traits]);

  const loadCollectionList = (page) => {
    setNewSearch(false);
    let url;
    if (traits !== '') {
      // url = `http://localhost:3001/gallery?q=${traits}&_page=${page}&_limit=6`;
      url = `${process.env.REACT_APP_KHUGA_API_BASE_URL}/collection/gallery?q=${traits}&page=${page}&perPage=6`;
    } else {
      // url = `http://localhost:3001/gallery?q=${search}&_page=${page}&_limit=6`;
      url = `${process.env.REACT_APP_KHUGA_API_BASE_URL}/collection/gallery?q=${search}&page=${page}&perPage=6`;
    }
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        const newList = newSearch ? data : collectionList.concat(data?.data);
        setCollectionList(newList);
        if (data.length === 0) {
          setHasMoreItems(false);
        } else {
          setHasMoreItems(true);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const handleTraitChanges = (data) => {
    setNewSearch(true);
    setTraits(data.join(' '));
  }

  return (
    <LayoutHome>
      <div className="container mx-auto px-4 md:px-10 relative">
        <div className="fixed md:hidden bottom-2 inset-x-0 z-10">
          <div className="flex justify-center items-center space-x-2">
            {showFilter ? (
              <>
                <div
                  className="relative hover:opacity-90 hover:shadow transition-all cursor-pointer"
                  onClick={() => setShowFilter((showFilter) => !showFilter)}
                >
                  <img src={`${process.env.PUBLIC_URL}/images/button/filter-dark.webp`} alt="Menu" className="h-11 mx-auto" />
                  <div className="absolute left-0 top-0 w-full text-center mt-2 text-white font-cursive">
                    <div className="flex justify-center items-center space-x-2">
                      <span>x</span>
                      <span>Cancel</span>
                    </div>
                  </div>
                </div>
                <div
                  className="relative hover:opacity-90 hover:shadow transition-all cursor-pointer"
                  onClick={() => setShowFilter((showFilter) => !showFilter)}
                >
                  <img src={`${process.env.PUBLIC_URL}/images/button/filter-light.webp`} alt="Menu" className="h-11 mx-auto" />
                  <div className="absolute left-0 top-0 w-full text-center mt-2 text-white font-cursive">
                    <div className="flex justify-center items-center space-x-2">
                      <span>Update</span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div
                className="relative hover:opacity-90 hover:shadow hover:-translate-y-1 transition-all cursor-pointer"
                onClick={() => setShowFilter((showFilter) => !showFilter)}
              >
                <img src={`${process.env.PUBLIC_URL}/images/button/filter-light.webp`} alt="Menu" className="h-11 mx-auto" />
                <div className="absolute left-0 top-0 w-full text-center mt-2 text-white font-cursive">
                  <div className="flex justify-center items-center space-x-2">
                    <Icon icon="carbon:settings-adjust" className="w-5 h-5" />
                    <span>Filter</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-8 py-10 relative min-h-screen">
          <div className={`bg-white absolute -left-full inset-y-0 w-full md:relative md:left-0 transition-all ${showFilter ? "translate-x-full" : ""}`}>
            <div className="sticky md:top-32 bg-transparent md:bg-[#F1F1F2] p-4 md:p-6 rounded-xl space-y-4">
              <h2 className="font-cursive text-xl">Filter</h2>
              <div className="relative">
                <div className="absolute left-3 top-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="1.5em"
                    height="1.5em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m21 21l-6-6m2-5a7 7 0 1 1-14 0a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  className="w-full p-3 pl-10 bg-transparent border border-gray-300"
                  placeholder="Search"
                  onChange={(e) => {
                    setNewSearch(true);
                    setSearch(e.target.value);
                  }}
                />
              </div>
              <div className="divide-y divide-gray-500">
                <TraitGroup title="Class" items={["Shiro", "Kuro", "Fat-P", "Spin-X", "Saber"]} expand={true} onChange={handleTraitChanges} />
                <TraitGroup title="Headgers" items={["Shiro 2", "Kuro 2", "Fat-P 2", "Spin-X 2", "Saber 2"]} expand={false} onChange={handleTraitChanges} />
                <TraitGroup title="Eyes" items={["Shiro 3", "Kuro 3", "Fat-P 3", "Spin-X 3", "Saber 3"]} expand={false} onChange={handleTraitChanges} />
              </div>
            </div>
          </div>
          <div className="col-span-3 md:col-span-2">
            <InfiniteScroll
              threshold={0}
              pageStart={0}
              loadMore={loadCollectionList}
              hasMore={hasMoreItems}
              loader={
                <div key={0} className="grid grid-cols-2 md:grid-cols-3 gap-8">
                  <CollectionLoader />
                  <CollectionLoader />
                  <CollectionLoader />
                </div>
              }
            >
              <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
                {collectionList.map((item, i) => (
                  <div className="text-center" key={i}>
                    <img src={item.collections.image} alt={item.collections.name} className="w-full rounded-xl mb-1" />
                    <div className="flex justify-center items-center">
                      <h3 className="text-sm">
                        <strong>{item.collections.name}</strong>
                      </h3>
                    </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
            {hasMoreItems ? "" : <div className="text-center py-4">All data has been loaded</div>}
          </div>
        </div>
      </div>

      {/* <footer className="relative w-full bg-black text-white z-0">
        <img src={`${process.env.PUBLIC_URL}/images/bg/footer.webp`} alt="BG" className="hidden md:block absolute bottom-0 w-full inset-x-0 z-0" />
        <img src={`${process.env.PUBLIC_URL}/images/bg/footer-mobile.webp`} alt="BG" className="block md:hidden absolute bottom-0 w-full inset-x-0 z-0" />
        <div className="max-w-6xl mx-auto px-4 pb-2 sm:pb-4 relative z-10 text-center md:text-left">
          <span className="font-bold text-xs">©2023 Khuga NFT</span>
        </div>
      </footer> */}
      <footer className="bg-white text-center py-5 text-sm">©2023 Khuga NFT</footer>
    </LayoutHome>
  );
}

export default Gallery;
