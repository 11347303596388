import { Icon } from "@iconify/react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import ProfileBox from "../../partials/miawwhalla/ProfileBox";
import User from "../../partials/miawwhalla/User";

function MiawwhallaEvent() {
  return (
    <>
      <Toaster />
      {/* Show on mobile */}
      <div className="fixed inset-0 bg-white z-50 flex justify-center items-center lg:hidden">
        <div className="text-center px-4">
          <Icon icon="akar-icons:circle-x" className="w-10 h-10 mx-auto text-red-400" />
          <h2 className="font-cursive text-3xl mt-4">Sorry</h2>
          <p>Miawwhalla is only accessible via desktop</p>
          <Link to={`${process.env.PUBLIC_URL}/`} className="px-4 py-2 ml-2 mr-4 mt-6 bg-primary-800 text-white rounded inline-block font-cursive text-sm">
            Back to home
          </Link>
        </div>
      </div>

      {/* Show on desktop */}
      <div
        className="min-h-screen bg-cover bg-center overflow-hidden"
        style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/miawwhalla/bg.webp')` }}
      >
        <div className="container mx-auto">
          <div className="flex justify-between items-start pt-6">
            <User className="mt-4" />
            <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/logo.webp`} alt="Logo" className="h-32" />
          </div>
        </div>

        <div className="grid grid-cols-2 items-center">
          <div className="relative">
            <ProfileBox />
          </div>
          <div
            className="relative ml-8 h-full bg-cover bg-no-repeat"
            style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/miawwhalla/bg-event.webp')` }}
          >
            <div className="w-11/12 ml-auto mt-24 h-3/4 overflow-y-auto text-right">
              <Link to={`${process.env.PUBLIC_URL}/miawwhalla/event/1`}>
                <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/event1.webp`} alt="" className="w-full ml-auto hover:-translate-x-2 transition-all" />
              </Link>
              <Link to={`${process.env.PUBLIC_URL}/miawwhalla/event/2`}>
                <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/event2.webp`} alt="" className="w-full ml-auto hover:-translate-x-2 transition-all" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MiawwhallaEvent;
