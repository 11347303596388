import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";

export default function WalletAddress() {
  // const wallet = useWallet();
  const [metamaskAddress, setMetamaskAddress] = useState();
  useEffect(() => {
    setMetamaskAddress(localStorage.getItem("metamask_address"));
  }, []);

  return (
    <div className="absolute top-20 inset-x-0">
      <div
        className="mb-6 pt-4 h-24 bg-contain bg-center bg-no-repeat"
        style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/whitelist/wallet-board.webp')` }}
      >
        <button
          className="hover:scale-105 active:scale-100 transition-all"
          onClick={async () => {
            localStorage.removeItem("metamask_address");
            localStorage.removeItem("x-auth-khuga");
            localStorage.removeItem("x-user-khuga");
            window.location.reload();
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/images/whitelist/btn-disconnect.webp`} alt="Disconnect" className="h-8" />
        </button>
        <div className="font-bold">{metamaskAddress}</div>
      </div>
    </div>
  );
}
