import { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const checkLoggedIn = async () => {
      let cuser = isAuthenticated();
      if (cuser === null) {
        localStorage.removeItem("x-auth-khuga");
        localStorage.removeItem("x-user-khuga");
        cuser = null;
      }

      setCurrentUser(cuser);
    };

    checkLoggedIn();
  }, []);

  return <UserContext.Provider value={[currentUser, setCurrentUser]}>{children}</UserContext.Provider>;
};

export const isAuthenticated = () => {
  const getKey = (array, key) => array.map((a) => a[key]);

  const user = localStorage.getItem("x-user-khuga");
  if (!user) {
    return null;
  }

  let parsedUser = JSON.parse(user);
  if (getKey(parsedUser.roles, "name")[0] !== undefined) {
    parsedUser.roles = getKey(parsedUser.roles, "name");
  }
  return parsedUser;
};