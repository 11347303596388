import LayoutHome from "../layouts/LayoutHome";
import toast, { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WalletModalProvider, WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { isMobile } from "react-device-detect";
import { useWallet } from "@solana/wallet-adapter-react";
import { useWeb3Auth } from "../web3/solana/auth";
import { useAuthenticatedHttpClient } from "../utils/authenticated-api-call";
import LoadingSpinner from "../partials/LoadingSpinner";

function Auth() {
  const wallet = useWallet();
  const navigate = useNavigate();
  const { doWeb3Auth } = useWeb3Auth();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    if (localStorage.getItem("x-auth-khuga")) {
      setTimeout(() => {
        return navigate(`${process.env.PUBLIC_URL}/miawwhalla`);
      }, 500);
    }

    console.log(wallet);
    if (wallet.connecting) {
      setIsLoading(true);
    } else if (!wallet.connecting && !wallet.connected) {
      setIsLoading(false);    
    } else if (!wallet.connecting && !wallet.disconnecting && wallet.connected) {
      console.log("connected");

      // ------------------------
      // Web3
      // ------------------------

      // Get Nonce
      setIsLoading(true);
      console.log("Initiating Web3 Authentication");
      const nonce = await internalAPIClient.get(`/web3/auth?public_key=${wallet.publicKey}`).catch((e) => {
        setIsLoading(false);
        wallet.disconnect();
        return toast.error("Authentication Error: " + e);
      });

      console.log(`Nonce is: ${nonce?.data?.data?.nonce}`);

      if (nonce?.data?.data?.nonce !== undefined) {
        // Sign Message
        setIsLoading(true);
        console.log("Please Sign the Authentication with Wallet");
        try {
          const signature = await doWeb3Auth(wallet.publicKey, nonce?.data?.data?.nonce);

          // Verify
          setIsLoading(true);
          console.log("Verifying Message Signing");
          const verify = await internalAPIClient
            .post("/web3/verify", {
              public_key: wallet.publicKey,
              signature: signature,
            })
            .catch((e) => {
              setIsLoading(false);
              wallet.disconnect();
              return toast.error("Authentication Error: " + e);
            });

          setIsLoading(false);
          console.log(verify);
          if (verify?.data?.data?.haveNFT === true) {
            setTimeout(() => {
              location.reload();
              // return navigate(`${process.env.PUBLIC_URL}/miawwhalla`);
            }, 500);
          } else {
            wallet.disconnect();
            return toast.error("You don't have any NFT");
          }
        } catch (e) {
          setIsLoading(false);
          wallet.disconnect();
          return toast.error("Authentication Error: " + e);
        }
      }
    }
  }, [wallet]);

  return (
    <LayoutHome>
      {isLoading && <LoadingSpinner />}

      <div className="w-full h-screen bg-[#C2C2C2]">
        <div className="absolute z-0 inset-0 bg-cover bg-bottom bg-no-repeat bg-[url('../../public/images/bg/miawhalla-mobile.webp')] sm:bg-[url('../../public/images/bg/miawhalla.webp')]"></div>

        <div className="relative flex justify-center items-center h-full z-10">
          <div className="-translate-y-52 sm:translate-y-12">
            {isMobile && window.solana === undefined ? (
              <a
                href={`https://phantom.app/ul/browse/${encodeURIComponent("https://khuga.io/auth")}?ref=${encodeURIComponent(
                  "https://khuga.io"
                )}`}
                className="relative hover:opacity-80 hover:shadow hover:-translate-y-1 transition-all"
              >
                <img src="/images/button/connect-wallet.webp" alt="Menu" className="h-20" />
                <span className="absolute top-0 w-full text-center mt-6 text-white font-cursive text-lg">Open in Phantom App</span>
              </a>
            ) : (
              <WalletModalProvider>
                <div className="flex justify-center items-center">
                  <WalletMultiButton className="relative hover:opacity-80 hover:shadow hover:-translate-y-1 transition-all !p-0">
                    <img src={`${process.env.PUBLIC_URL}/images/button/connect-wallet.webp`} alt="Menu" className="h-20" />
                    <span className="absolute top-0 w-full text-center mt-2 text-white font-cursive text-lg">Connect With Your Wallet</span>
                  </WalletMultiButton>
                </div>
              </WalletModalProvider>
            )}
          </div>
        </div>
      </div>

      <footer className="absolute inset-x-0 bottom-0 z-10 bg-black text-white py-2 md:py-4">
        <div className="max-w-6xl mx-auto px-4 pb-2 sm:pb-4 relative z-10 text-center md:text-left">
          <span className="font-bold text-xs">©2023 Khuga NFT</span>
        </div>
      </footer>

      <Toaster />
    </LayoutHome>
  );
}

export default Auth;
