import React from "react";
import { Toaster } from "react-hot-toast";

function LayoutWhitelist(props) {
  return (
    <>
      <main className="bg-white bg-center bg-cover" style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/bg/whitelist.webp')` }}>
        {props.children}
      </main>
      <Toaster />
    </>
  );
}

export default LayoutWhitelist;
