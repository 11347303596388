import toast, { Toaster } from "react-hot-toast";
import { Fragment, useEffect, useState } from "react";
import LoadingSpinner from "../partials/LoadingSpinner";
import { Dialog, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import { isMobile } from "react-device-detect";
import { getClaimedAmount, getMaxSupply, getMintedAmount, getMintPrice, getMintStage, isPaused, KhugaStageMaxAmountPerAddress, KhugaStageMaxAmountPerTx, publicMint, whitelistMint } from "../web3/eth/nft";
import unrevealBox from "../lotties/unreveal-box";
import unrevealMint from "../lotties/unreveal-mint";
import Lottie from "react-lottie";
import axios from "axios";
import Countdown from "react-countdown";
import moment from "moment/moment";

const stageStatus = ["Miawwlist Mint", "Allowlist Mint", "Public Mint"];

const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return window.location.reload();
  } else {
    let newHours = (days * 24) + hours;
    return (
      <span>
        {newHours}:{minutes}:{seconds}
      </span>
    );
  }
};

function MintPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [metamaskAddress, setMetamaskAddress] = useState();
  const [isMinted, setIsMinted] = useState(false);
  const [stage, setStage] = useState(255);
  const [price, setPrice] = useState(0);
  const [mintedAmount, setMintedAmount] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [amount, setAmount] = useState(2);
  const [pausedAnimation, setPausedAnimation] = useState(false);
  const [markleProof, serMarkleProof] = useState([]);
  const [whitelistLevel, setWhitelistLevel] = useState(null);
  const [diffDate, setDiffDate] = useState(null);
  const [isPausedTime, setIsPausedTime] = useState(false);
  const [pausedDate, setPausedDate] = useState(null);

  useEffect(async () => {
    setIsLoading(true);

    setMetamaskAddress(localStorage.getItem("metamask_address"));

    // set paused
    const startPausedTime = moment.utc("2023-01-27T01:30:00+0000").diff(moment.utc(moment.utc().local()));
    const endPausedTime = moment.utc("2023-01-27T02:00:00+0000").diff(moment.utc(moment.utc().local()));
    if (startPausedTime < 0 && endPausedTime > 0) {
      setIsPausedTime(true);
      setPausedDate(endPausedTime);
    }

    // stage
    const getStage = (await getMintStage()).toNumber();
    setStage(getStage);

    if (getStage === 255) {
      const getTime = moment.utc("2023-01-26T15:00:00+0000").diff(moment.utc(moment.utc().local()));
      setDiffDate(getTime);
    } else if (getStage === 0) {
      const getTime = moment.utc("2023-01-26T21:30:00+0000").diff(moment.utc(moment.utc().local()));
      setDiffDate(getTime);
    } else if (getStage === 1) {
      const getTime = moment.utc("2023-01-27T01:30:00+0000").diff(moment.utc(moment.utc().local()));
      setDiffDate(getTime);
    }

    // price
    if (getStage !== 255) {
      const getPrice = await getMintPrice(getStage);
      setPrice(getPrice);
    }

    // minted amount
    const getMinted = await getMintedAmount();
    setMintedAmount(getMinted);

    // get supply
    const getSupply = await getMaxSupply();
    setMaxSupply(getSupply);

    // setStage(0);
    // setPrice(0.394435);
    // setMintedAmount(10);
    // setMaxSupply(100);
    
    setIsLoading(false);
  }, []);

  useEffect(async () => {
    if (metamaskAddress) {
      if (stage === 0 || stage === 1) {
        await axios
          .get(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/merkleproof/${metamaskAddress}?whitelist_level=${stage}`)
          .then((res) => {
            if (res?.data?.status === "success" && res?.data?.data?.proof) {
              serMarkleProof(res?.data?.data?.proof ?? null);
              setWhitelistLevel(res?.data?.data?.whitelist_level ?? null);
            } else {
              toast.error("You are not allowed on this phase");
            }
          })
          .catch((err) => {
            console.log(err);
            // toast.error("Error occured!")
          });
      }
    }
  }, [metamaskAddress, stage])

  const handleConnectMetamask = async () => {
    setIsLoading(true);

    if (!window.ethereum) {
      setIsLoading(false);
      toast.error("Install metamask extension!");
      return;
    }

    let accounts;
    try {
      accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
    } catch (e) {
      console.log(e);
      toast.error("Failed to connect to wallet");
      setIsLoading(false);
      return;
    }

    localStorage.setItem("metamask_address", accounts[0] ?? null);
    setIsLoading(false);
    window.location.reload();
  };

  const handleMint = async () => {
    const getIsPaused = await isPaused();
    if (getIsPaused) {
      toast.dismiss();
      return toast.error("Contract is paused!");
    }

    if (stage === 255) {
      toast.dismiss();
      toast.error("Not started!");
      return;
    } else if (stage === 0 || stage === 1) {
      if (markleProof == null) {
        toast.dismiss();
        return toast.error("Forbidden: Not Whitelisted");
      }

      if (stage != whitelistLevel) {
        toast.dismiss();
        return toast.error("Forbidden: Mint Stage is Over or Not Started");
      }

      setIsLoading(true);

      const claimedAmount = await getClaimedAmount(whitelistLevel, metamaskAddress);
      if (claimedAmount + 1 > KhugaStageMaxAmountPerAddress[whitelistLevel]) {
        setIsLoading(false);
        toast.dismiss();
        return toast.error("Forbidden: Maximum Allowed Claim Reached");
      };

      await whitelistMint(stage, 1, markleProof)
        .then((res) => {
          console.log(res);
          setIsMinted(true);
        })
        .catch((err) => {
          console.log(err);
          return toast.error("Failed to mint!");
        });
      setIsLoading(false);
    } else if (stage === 2) {
      setIsLoading(true);

      const claimedAmount = await getClaimedAmount(2, metamaskAddress);
      if (claimedAmount + amount > KhugaStageMaxAmountPerAddress[stage]) {
        setIsLoading(false);
        toast.dismiss();
        return toast.error("Forbidden: Maximum Allowed Claim Reached");
      }

      await publicMint(amount)
        .then((res) => {
          console.log(res);
          setIsMinted(true);
        })
        .catch((err) => {
          console.log(err);
          return toast.error("Failed to mint!");
        });
      setIsLoading(false);
    }
  };

  const handleChangeAmount = (action) => {
    if (action == "plus") {
      if (KhugaStageMaxAmountPerTx[stage] > amount) {
        setAmount(amount + 1);
      }
    } else if (action == "minus") {
      if (amount > 1) {
        setAmount(amount - 1);
      }
    }
  }

  const handleDisconnectWallet = () => {
    localStorage.removeItem("metamask_address");
    window.location.reload();
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className="w-full min-h-screen bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/bg/mint.webp')` }}>
        {metamaskAddress && (
          <div className="absolute right-2 top-2 md:right-4 md:top-4 flex items-center">
            <div className="p-2 bg-white">{metamaskAddress.substr(0, 4) + "..." + metamaskAddress.substr(-4)}</div>
            <button onClick={handleDisconnectWallet} className="p-2 bg-red-600 text-white">
              Disconnect
            </button>
          </div>
        )}

        <div className="flex-1 max-w-lg mx-auto px-4">
          <div className="relative z-10 text-center text-white">
            {isPausedTime ? (
              <h1 className="font-bold font-cursive text-3xl md:text-4xl">Getting Ready for Public Mint</h1>
            ) : (
              <h1 className="font-bold font-cursive text-3xl md:text-4xl">{stage === 255 ? "Not Started" : stageStatus[stage]}</h1>
            )}
            {stage !== 255 && <p className="text-lg">{price} ETH</p>}
            {(stage === 255 || stage === 0 || stage === 1 || isPausedTime) && (diffDate !== null || pausedDate !== null) && (
              <h6 className="font-bold font-cursive text-4xl md:text-5xl mt-4">
                <Countdown date={Date.now() + (pausedDate ?? diffDate)} renderer={rendererCountDown} />
              </h6>
            )}
          </div>

          <div className="relative z-0 -my-16 md:-my-32 overflow-hidden">
            <Lottie
              isClickToPauseDisabled={true}
              options={{
                loop: true,
                autoplay: true,
                isPaused: pausedAnimation,
                animationData: isMinted ? unrevealMint : unrevealBox,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              width={isMobile ? 280 : 500}
              height={isMobile ? 280 : 500}
              className="mx-auto"
            />
          </div>

          {!isPausedTime && (
            <div className="relative z-10 mt-12 px-4">
              {isMinted ? (
                <div className="text-center mt-8">
                  <h2 className="text-white font-bold text-3xl mb-4">Congratulations!</h2>
                  <p className="text-white/80 text-lg">
                    Your NFT has been minted successfully, and the adoption of Khuga is complete. Be ready for the new adventure!
                  </p>
                </div>
              ) : (
                <>
                  {metamaskAddress ? (
                    <div className="mb-8">
                      {stage === 2 && (
                        <div className="flex items-center justify-between mb-6">
                          <p className="text-white">Amount</p>
                          <div className="flex items-center gap-2">
                            <button
                              onClick={() => handleChangeAmount("minus")}
                              className="p-3 rounded-full bg-white hover:scale-110 active:scale-100 transition-all"
                            >
                              <Icon icon="heroicons-outline:minus" />
                            </button>
                            <input type="text" value={amount} disabled className="w-full bg-gray-300 rounded cursor-not-allowed" />
                            <button
                              onClick={() => handleChangeAmount("plus")}
                              className="p-3 rounded-full bg-white hover:scale-110 active:scale-100 transition-all"
                            >
                              <Icon icon="heroicons-outline:plus" />
                            </button>
                          </div>
                        </div>
                      )}
                      <button
                        onClick={() => handleMint()}
                        className="w-full p-3 bg-primary-500 text-white tracking-widest font-bold text-2xl rounded-xl hover:contrast-150"
                      >
                        MINT
                      </button>
                    </div>
                  ) : (
                    <>
                      {!window.ethereum && isMobile ? (
                        <a
                          href="https://metamask.app.link/dapp/https://mint.khuga.io"
                          className="my-12 w-full p-3 bg-primary-500 text-white tracking-wider font-medium text-2xl rounded-xl block text-center"
                        >
                          Connect Wallet
                        </a>
                      ) : (
                        <button
                          onClick={handleConnectMetamask}
                          className="my-12 w-full p-3 bg-primary-500 text-white tracking-wider font-medium text-2xl rounded-xl"
                        >
                          Connect Wallet
                        </button>
                      )}
                    </>
                  )}

                  <p className="text-green-500">Live</p>
                  <div className="w-full flex items-center gap-8">
                    <div className="relative flex-1 bg-white/50 w-full h-6 rounded-full overflow-hidden">
                      <div className="absolute inset-0 bg-white rounded-full" style={{ width: `${(mintedAmount / maxSupply) * 100}%` }}></div>
                    </div>
                    <div className="text-white font-medium text-xl tracking-wider">
                      {mintedAmount}/{maxSupply}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      <Toaster />

      {/* <Transition appear show={isMinted} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsMinted(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform rounded-2xl bg-white/50 backdrop-blur-md p-6 text-left align-middle shadow-xl transition-all">
                  <div className="text-center relative">
                    <button onClick={() => setIsMinted(false)} className="absolute -right-10 -top-10 bg-red-500 text-white p-2 rounded-full">
                      <Icon icon="heroicons-solid:x" className="w-6 h-6" />
                    </button>
                    <img src="/images/box-adopt.webp" alt="Adopt Me" className="w-72 mx-auto mb-6" />

                    <Dialog.Title as="h3" className="text-2xl font-bold text-gray-900">
                      Congratulations!
                    </Dialog.Title>

                    <p className="text-sm text-white">
                      Your NFT has been minted successfully, and the adoption of Khuga is complete. Be ready for the new adventure!
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition> */}
    </>
  );
}

export default MintPage;
