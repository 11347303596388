import { Icon } from "@iconify/react";
import React from "react";
import AdminDataTransaction from "./pages/Admin/AdminDataTransaction";
import AdminDataWhitelist from "./pages/Admin/AdminDataWhitelist";
import Checkout from "./pages/Checkout";
import Collections from "./pages/Collections";
import Dashboard from "./pages/Dashboard";
import DigitalGoodies from "./pages/DigitalGoodies";
import Merchandise from "./pages/Merchandise";
import MiawwhallaBattle from "./pages/Miawwhalla/Battle";
import MiawwhallaCollections from "./pages/Miawwhalla/Collections";
import MiawwhallaDashboard from "./pages/Miawwhalla/Dashboard";
import MiawwhallaEvent from "./pages/Miawwhalla/Event";
import MiawwhallaEventDetail from "./pages/Miawwhalla/EventDetail";
import MiawwhallaProfile from "./pages/Miawwhalla/Profile";
import Profile from "./pages/Profile";
import TransactionConfirm from "./pages/TransactionConfirm";
import Transactions from "./pages/Transactions";

const routes = [
  {
    type: "menu",
    name: "Home",
    key: "home",
    route: "/home",
    roles: ["user", "superadmin"],
    component: <Dashboard />,
    icon: <Icon icon="heroicons-outline:home" className="w-6 h-6" />,
  },
  {
    type: "menu",
    name: "Collections",
    key: "collections",
    route: "/collections",
    roles: ["user", "superadmin"],
    component: <Collections />,
    icon: <Icon icon="heroicons-outline:view-grid" className="w-6 h-6" />,
  },
  {
    type: "menu",
    name: "Merchandise",
    key: "merchandise",
    route: "/merchandise",
    roles: ["user", "superadmin"],
    component: <Merchandise />,
    icon: <Icon icon="heroicons-outline:shopping-bag" className="w-6 h-6" />,
    submenu: [
      {
        type: "submenu",
        name: "Order",
        key: "order",
        route: "/merchandise",
        roles: ["user", "superadmin"],
      },
      {
        type: "submenu",
        name: "Transactions",
        key: "transactions",
        route: "/transactions",
        roles: ["user", "superadmin"],
      },
    ],
  },
  // {
  //   type: "menu",
  //   name: "Digital Goodies",
  //   key: "digital-goodies",
  //   route: "/digital-goodies",
  //   roles: ["user", "superadmin"],
  //   component: <DigitalGoodies />,
  //   icon: <Icon icon="heroicons-outline:cube" className="w-6 h-6" />,
  // },
  {
    type: "menu",
    name: "Data Transaction",
    key: "admin-transaction",
    route: "/admin/transaction",
    roles: ["superadmin"],
    component: <AdminDataTransaction />,
    icon: <Icon icon="heroicons-outline:clipboard-list" className="w-6 h-6" />,
  },
  {
    type: "menu",
    name: "Data Whitelist",
    key: "admin-whitelist",
    route: "/admin/whitelist",
    roles: ["superadmin"],
    component: <AdminDataWhitelist />,
    icon: <Icon icon="heroicons-outline:clipboard-list" className="w-6 h-6" />,
  },
  {
    type: "route",
    name: "Miawhalla",
    key: "miawwhalla",
    route: "/miawwhalla",
    roles: ["user", "superadmin"],
    component: <MiawwhallaDashboard />,
    icon: <Icon icon="heroicons-outline:home" className="w-6 h-6" />,
  },
  {
    type: "route",
    name: "Miawhalla Profile",
    key: "miawwhalla-profile",
    route: "/miawwhalla/profile",
    roles: ["user", "superadmin"],
    component: <MiawwhallaProfile />,
    icon: <Icon icon="heroicons-outline:home" className="w-6 h-6" />,
  },
  {
    type: "route",
    name: "Miawhalla Event",
    key: "miawwhalla-event",
    route: "/miawwhalla/event",
    roles: ["user", "superadmin"],
    component: <MiawwhallaEvent />,
    icon: <Icon icon="heroicons-outline:home" className="w-6 h-6" />,
  },
  {
    type: "route",
    name: "Miawhalla Event",
    key: "miawwhalla-event-detail",
    route: "/miawwhalla/event/:id",
    roles: ["user", "superadmin"],
    component: <MiawwhallaEventDetail />,
  },
  {
    type: "route",
    name: "Miawhalla Battle",
    key: "miawwhalla-battle",
    route: "/miawwhalla/battle/:id",
    roles: ["user", "superadmin"],
    component: <MiawwhallaBattle />,
  },
  {
    type: "route",
    name: "Miawhalla Collection",
    key: "miawwhalla-collection",
    route: "/miawwhalla/collection",
    roles: ["user", "superadmin"],
    component: <MiawwhallaCollections />,
    icon: <Icon icon="heroicons-outline:home" className="w-6 h-6" />,
  },
  {
    type: "route",
    name: "Transaction",
    key: "transaction",
    route: "/transactions",
    roles: ["user", "superadmin"],
    component: <Transactions />,
    icon: <Icon icon="heroicons-outline:clipboard-list" className="w-6 h-6" />,
  },
  {
    type: "route",
    name: "Confirm Transaction",
    key: "confirm-transaction",
    route: "/transactions/:id",
    roles: ["user", "superadmin"],
    component: <TransactionConfirm />,
  },
  {
    type: "route",
    name: "Checkout",
    key: "checkout",
    route: "/checkout",
    roles: ["user", "superadmin"],
    component: <Checkout />,
  },
  {
    type: "route",
    name: "Profile",
    key: "profile",
    route: "/profile",
    roles: ["user", "superadmin"],
    component: <Profile />,
  },
];

export default routes;