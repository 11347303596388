import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import LoadingSpinner from "../LoadingSpinner";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

export default function WhitelistChecker({ position = "fixed" }) {
  const [isOpenWhitelist, setIsOpenWhitelist] = useState(false);
  const inputWhitelistAddress = useRef();
  const [isWhitelisted, setIsWhitelisted] = useState(undefined);
  const [whitelistRole, setWhitelistRole] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckWhitelist = () => {
    if (inputWhitelistAddress.current.value === "") {
      setIsWhitelisted(undefined);
      toast.error("Wallet address is required");
      return;
    }

    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/whitelist-status/${inputWhitelistAddress.current.value}`)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status === "success") {
          setIsWhitelisted(res?.data?.data?.whitelisted);
          setWhitelistRole(res?.data?.data?.whitelist_role);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Error occured!");
      });
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}

      <div
        className={`${
          position === "fixed" ? "fixed right-4 md:right-8 bottom-32 md:bottom-36" : "relative top-4 w-40 mx-auto"
        } z-10 bg-primary-800 text-white border-4 border-white rounded-full px-6 py-3 cursor-pointer flex items-center space-x-2 font-cursive text-sm shadow-xl hover:-translate-y-1 hover:shadow-2xl transition-all`}
        onClick={() => {
          setIsWhitelisted(undefined);
          setIsOpenWhitelist(true);
        }}
      >
        <Icon icon="material-symbols:account-balance-wallet" className="w-5 h-5" />
        <span>Check WL</span>
      </div>

      <Transition appear show={isOpenWhitelist} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpenWhitelist(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-xl transform rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-xl font-cursive mb-4">
                    Check Whitelist
                  </Dialog.Title>
                  <div className="my-2 flex items-center space-x-2">
                    <input ref={inputWhitelistAddress} type="text" className="flex-1 rounded-md border-primary-800" placeholder="Wallet Address" />
                    <button
                      className="shrink-0 bg-primary-800 text-white font-bold px-4 py-2.5 rounded-md hover:contrast-125"
                      onClick={() => handleCheckWhitelist()}
                    >
                      Check
                    </button>
                  </div>
                  {isWhitelisted !== undefined && isWhitelisted === true && (
                    <>
                      <p className="font-bold text-green-500">
                        Wallet address has been registered{whitelistRole && ` as ${whitelistRole} ${whitelistRole === "Miawwlist" ? "(Guaranteed)" : "(FCFS)"}`}
                      </p>
                    </>
                  )}
                  {isWhitelisted !== undefined && isWhitelisted === false && (
                    <p className="font-bold text-red-500">
                      Wallet address not registered!{" "}
                      <Link to={`${process.env.PUBLIC_URL}/whitelist`} className="text-blue-500 underline">
                        Register now!
                      </Link>
                    </p>
                  )}
                  <div
                    onClick={() => setIsOpenWhitelist(false)}
                    className="cursor-pointer p-1.5 bg-red-500 text-white absolute w-8 h-8 rounded-full -right-3 -top-3"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512">
                      <path
                        fill="currentColor"
                        d="m289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34Z"
                      />
                    </svg>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Toaster />
    </>
  );
}
