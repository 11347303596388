import { createContext, useEffect, useState } from "react";
import { useAuthenticatedHttpClient } from "../utils/authenticated-api-call";

export const UserCollectionsContext = createContext();

export const UserCollectionsProvider = ({ children }) => {
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [userCollections, setUserCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState([]);

  useEffect(() => {
    const checkCollections = async () => {
      // internalAPIClient
      //   .get(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/collection`)
      //   .then((res) => {
      //     const data = res?.data?.data ?? [];
      //     setUserCollections(data);

      //     setUserCollections(data);
      //     if (data.length > 0) {
      //       setSelectedCollection(data[0]);
      //     }
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
      const data = [
        { id: 1, collection: { name: "Testing 1", image: "/images/team/aji.webp" } },
        { id: 2, collection: { name: "Testing 2", image: "/images/team/alip.webp" } },
        { id: 3, collection: { name: "Testing 3", image: "/images/team/bar-z.webp" } },
      ];
      setUserCollections(data);
      if (data.length > 0) {
        setSelectedCollection(data[0]);
      }
    };

    checkCollections();
  }, []);

  return <UserCollectionsContext.Provider value={[userCollections, selectedCollection, setSelectedCollection]}>{children}</UserCollectionsContext.Provider>;
};